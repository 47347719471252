.b-load_progress {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: 30px;

	&-description {
		color: $color-text-dimmed;
		font-size: 13px;
		line-height: 1.4;
		margin-bottom: 20px;
	}
}
