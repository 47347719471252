.b-zoom_info {
	@include media(lg-up) {
		align-items: center;
		display: flex;
		pointer-events: none;
	}

	@include media(md-down) {		
		.b-photoswipe & {
			background-color: rgba($color-bg-shade, 0.8);
			border-radius: 40px;
			font-size: 15px;
			font-weight: 500;
			left: 50%;
			margin-top: -20px;
			padding: 10px 20px;
			position: absolute;
			top: 50%;
			transform: translate(-50%);
			z-index: 11;
		}

		.b-photoswipe.pswp--zoomed &,
		.b-photoswipe.pswp--html &,
		.b-photoswipe.pswp--zoomed-in & {
			display: none;
		}
	}

	&-icon {
		@include media(lg-up) {
			margin-right: 4px;
		}

		svg {
			@include media(lg-up) {
				height: 28px;
				width: 28px;
			}
		}
	}

	&-content {
		@include media(md-down) {
			display: none;
		}
	}
}
