.l-plp {
	border-top: 1px solid $color-border-grey;
	margin-top: 20px;

	@include media(lg-up) {
		display: flex;
	}

	@include media(md-up) {
		margin-top: 25px;
	}

	&.m-hybrid {
		border: none;
	}

	&-refinement {
		@include media(lg-up) {
			display: flex;
			flex-direction: column;
			max-width: 221px;
			position: relative;
			width: 100%;
		}

		@include media(md-down) {
			bottom: 0;
			display: flex;
			height: 100dvh; // stylelint-disable-line
			justify-content: flex-end;
			pointer-events: none;
			position: fixed;
			right: 0;
			top: 0;
			transition: $motion-ease-popups;
			transition-property: visibility, background-color;
			visibility: hidden;
			width: 100vw;
			z-index: 11;

			.b-refinements_panel {
				&-sticky {
					background-color: $color-bg-panel;
					height: 100%;
					max-height: 100vh;
					max-width: 400px;
					top: 0;
					transform: translateX(100%);
					transition: transform $motion-ease-popups;
					width: 75vw;
				}
			}

			/* stylelint-disable */
			&.-active {
				background: rgba($color-bg-overlay, 0.5);
				pointer-events: all;
				visibility: visible;

				.b-refinements_panel {
					&-sticky {
						transform: translateX(0);
					}
				}
			} /* stylelint-enable */
		}

		&.m-no_results {
			display: none;
		}
	}

	&-products {
		width: 100%;

		@include media(lg-up) {
			padding-left: 20px;

			&.m-no_results {
				margin: 20px 0 75px;
				width: 100%;
			}
		}

		&.m-no_results {
			margin-bottom: 0;
			padding: 0;
		}
	}

	&-questions {
		@include g-section_holder;

		&_container {
			@include media(xl) {
				margin: 0 auto;
				max-width: 1184px;
			}
		}
	}

	&-questions_content {
		background-color: $color-bg-qa-section;
		background-position: 50% 0;
		background-repeat: no-repeat;
		background-size: 100% auto;

		@include media(lg-up) {
			.b-product_accordion {
				margin: 0 auto;
				max-width: 820px;
			}
		}
	}
}
