.b-load_more {
	align-items: center;
	display: flex;
	flex-wrap: nowrap;
	justify-content: center;
	text-align: center;

	&-button {
		padding: 0 26px;
	}

	&-link {
		@include g-link;

		color: $color-action-obj;
		font-size: 15px;
		font-weight: 600;
		line-height: 22.5px;
		margin-left: 55px;
	}

	.b-history_page & {
		margin-bottom: 51px;
	}
}
