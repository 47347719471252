.b-plp_actions {
	align-items: center;
	display: flex;
	justify-content: space-between;
	padding: 20px 0;

	@include media(md-down) {
		background-color: $color-bg;
		margin: 0 -#{grid-margin(md)};
		padding: 20px #{grid-margin(md)};
		position: sticky;
		top: 51px;
		z-index: z(header-actions-sticky);

		.m-refinements_sticky & {
			box-shadow: $depth-2, 0 1px 0 0 $color-divider inset;
		}

		.b-header_slim & {
			top: 81px;
		}
	}

	@include media(sm) {
		margin: 0 -#{grid-margin(sm)};
		padding: 20px #{grid-margin(sm)};
	}

	.m-hybrid & {
		border-top: 1px solid $color-border-grey;
	}

	&-top {
		align-items: center;
		display: flex;
		justify-content: space-between;
		padding-bottom: 20px;

		@include media(md-up) {
			display: none;
		}
	}

	&-refinements_toggle {
		@include g-button;

		background: $color-action-obj;
		border: 2px solid $color-action-obj;
		border-radius: $global-radius;
		color: adjust-color-to-bg($color-action-obj);
		flex: 0 0 auto;
		font-weight: 500;
		letter-spacing: 0.03em;
		min-width: 97px;
		padding: 0 7px;

		@include media(md) {
			padding: 0 37px;
		}

		@include media(lg-up) {
			display: none;
		}
	}

	&-refinements_toggle_svg {
		margin-right: 4px;
	}

	&-sorting {
		align-items: center;
		display: flex;
		flex: 0 1 auto;
		height: $size-input-height;
		margin-left: auto;
		order: 2;
		padding-left: 5px;
		position: relative;

		@include media(md-up) {
			flex: 0 0 auto;
			order: 3;
		}
	}

	&-sorting_label {
		color: $color-primary;
		font-size: 13px;
		font-weight: 400;
		line-height: 1.4;
		margin-right: 10px;
		white-space: nowrap;
	}

	&-results_count {
		align-items: center;
		color: $color-primary;
		display: flex;
		flex: 1 0 70%;
		font-size: 13px;
		font-weight: 400;
		line-height: 1.4;
		order: 3;

		@include media(md-up) {
			flex: 1 1 0;
			order: 2;
		}

		@include media(md) {
			justify-content: center;
		}

		@include media(sm) {
			display: block;
		}

		&.m-mobile {
			@include media(sm) {
				display: none;
			}
		}
	}

	&-results_viewing {
		@include media(md-up) {
			margin: 0 5px;
		}
	}

	&-grid_toggle {
		flex: 0 1 auto;
		order: 4;

		@include media(md-up) {
			display: none;
		}

		@include media(sm) {
			margin-left: auto;
		}
	}

	&-grid_button {
		color: $color-border-underline;
		cursor: pointer;
		float: left;
		line-height: 1;
		margin-left: 20px;

		&.m-active {
			color: $color-action-obj;
		}
	}
}
