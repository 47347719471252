@use 'sass:math';

.b-product_video {
	overflow: hidden;
	position: relative;

	&::before {
		content: '';
		float: left;
		padding-bottom: percentage(math.div(720, 1280));
	}

	&-player,
	&-cover {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}

	&-image {
		border-radius: 4px;
		height: 100%;
		object-fit: cover;
		width: 100%;
	}
}
