.b-search_tips {
	margin-top: 41px;

	&-title {
		color: $color-primary;
		font-size: 15px;
		font-weight: 500;
		line-height: 1.5;
		margin: 16px 0;

		@include media(sm) {
			margin-bottom: 10px;
		}
	}

	&-list {
		color: $color-primary;
		font-size: 13px;
		line-height: 1.45;
		list-style: none;
	}

	&-item {
		margin-bottom: 8px;
		margin-left: 20px;
		padding-left: 8px;
		position: relative;

		&::before {
			background-color: $color-action-obj;
			border-radius: 50%;
			content: '';
			height: 6px;
			left: -8px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 6px;

			@include media(sm) {
				top: 6px;
				transform: translateY(0);
			}
		}
	}
}
