.l-plp_grid {
	align-items: stretch;
	display: grid;
	grid-auto-flow: dense;
	grid-gap: 30px 8px;
	grid-template-areas: '. . slot1 slot1';
	grid-template-columns: repeat(4, minmax(0, 1fr));
	justify-items: stretch;
	position: relative;

	@include media(md) {
		grid-gap: 30px 16px;
		grid-template-areas: '. slot1 slot1';
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}

	@include media(lg-up) {
		grid-gap: 30px 20px;
	}

	@include media(sm) {
		grid-gap: 20px 8px;
		grid-template-areas: 'slot1 slot1';
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}

	&::after {
		background-color: rgba($color-white, 0.6);
		bottom: 0;
		content: '';
		cursor: wait;
		left: 0;
		opacity: 0;
		position: absolute;
		right: 0;
		top: 0;
		transition: opacity $motion-ease;
		visibility: hidden;
	}

	&.m-busy {
		&::after {
			opacity: 1;
			visibility: visible;
		}
	}

	&.m-one_column {
		@include media(sm) {
			grid-gap: 30px 8px;
			grid-template-areas: 'slot1';
			grid-template-columns: 1fr;
		}
	}

	&.m-three_column {
		@include media(lg-up) {
			grid-template-areas: '. slot1 slot1';
			grid-template-columns: repeat(3, minmax(0, 1fr));
		}
	}

	&-actions {
		grid-column: 1/-1;
	}

	&-slot_1 {
		align-self: start;
		grid-area: slot1;
		height: initial;
	}
}
