.b-ideas_toggle {
	background-color: $color-bg-switcher-container;
	border-radius: 2px;
	display: inline-flex;
	justify-content: space-evenly;
	padding: 12px 5px 11px;
	width: 100%;

	@include media(lg-up) {
		.b-refinements_panel & {
			margin-bottom: 20px;
		}
	}

	&-wrapper {
		.b-wishlist.m-guest & {
			@include media(md-down) {
				order: 1;
			}
		}
	}

	&-container {
		align-items: center;
		display: flex;
	}

	&-title {
		align-items: center;
		color: $color-ideas-toggle-title;
		cursor: pointer;
		display: inline-flex;
		flex-shrink: 0;
		font-size: 13px;
		font-weight: 500;
		justify-content: center;
		line-height: 16px;
		transition: opacity $motion-ease;

		@include hover-supported {
			&:hover {
				opacity: 0.8;
			}
		}

		&.m-active {
			pointer-events: none;
		}
	}

	&-label {
		display: flex;
		flex-basis: 50%;
		flex-wrap: wrap;
		justify-content: center;
	}
}
