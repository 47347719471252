.b-age_restricted {
	&-badge {
		background-color: $color-fg-promo;
		border-radius: $global-radius;
		color: $color-bg;
		display: inline-block;
		font-size: 11px;
		grid-area: age;
		padding: 2px 4px 2px 2px;
		position: relative;

		.b-cart_product-item & {
			align-self: start;
			margin-top: 10px;
			max-width: 110px;
		}

		.b-order_product & {
			margin-top: 15px;
		}
	}

	&-icon {
		cursor: pointer;
	}

	&-message {
		background-color: $color-bg;
		border-radius: $global-radius;
		box-shadow: $depth-1;
		color: $color-primary;
		font-weight: 600;
		grid-area: message;
		left: 0;
		margin: 15px 0;
		max-width: 90vw;
		padding: 10px;
		position: absolute;
		text-align: left;
		width: 483px;
		z-index: 1;

		&::before {
			border: 10px solid $color-bg;
			border-color: transparent transparent $color-bg $color-bg;
			box-shadow: -3px 3px 5px 0 rgba($color-bg-overlay, 0.1);
			content: '';
			left: 5px;
			position: absolute;
			top: -10px;
			transform: rotate(135deg);
		}

		.b-cart_quickview-inner &,
		.b-order_product.m-confirmation & {
			@include media(sm) {
				max-width: 315px;
				transform: translateX(-30%);

				&::before {
					left: 30%;
				}
			}
		}
	}

	&-icon:focus + &-message {
		display: block;
	}
}
