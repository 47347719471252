.b-refinement_list {
	overflow: hidden; // prevent from margins push bounds over so scroll is showing up

	&-item {
		padding: 8px 0;
	}

	.b-slide_panel & {
		overflow: auto;
		padding: 5px 20px;

		&-item {
			padding: 12px 0;
		}
	}
}
