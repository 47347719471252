/*md

# b-promo_box

`promo-box` is basic container for creating promo boxes and banners.
It designed to position description (`b-promo_caption`) over the image box.

This implementation could handle both image and text centric approaches.

## Example

```html_example
<figure class="b-promo_box m-caption_center">
	<picture class="b-promo_box-picture">
		<source type="image/jpeg" media="(max-width: 767px)"
				srcset="../images/guide/examples/banner-16x9-sm.jpg?$staticlink$, ../images/guide/examples/banner-16x9-sm@2x.jpg?$staticlink$ 2x" />
		<source type="image/jpeg" media="(min-width: 768px) and (max-width: 1024px)"
				srcset="../images/guide/examples/banner-16x9-md.jpg?$staticlink$, ../images/guide/examples/banner-16x9-md@2x.jpg?$staticlink$ 2x" />
		<source type="image/jpeg" media="(min-width: 1025px)"
				srcset="../images/guide/examples/banner-16x9-lg.jpg?$staticlink$, ../images/guide/examples/banner-16x9-lg@2x.jpg?$staticlink$2x" />
		<img
			loading="lazy"
			src="../images/guide/examples/banner-16x9-lg.jpg?$staticlink$"
			alt="Some dummy alternative text for image."
			width="1600"
			height="800"
		/>
	</picture>
	<figcaption class="b-promo_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Explore More Possibilities
		</h2>
		<p class="b-promo_caption-subtitle">
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app.
		</p>
		<div class="b-promo_caption-actions">
			<a
				class="b-button"
				href="$url('Search-Show', 'cgid', 'category-2')$"
			>
				Shop Now
			</a>
		</div>
	</figcaption>
</figure>
```

## Overflow handling

This component is designed to handle any type of overlow without cutting text content.

### Very long text

```html_example
<figure class="b-promo_box m-caption_offcenter">
	<picture class="b-promo_box-picture">
		<img
			loading="lazy"
			src="../images/guide/examples/image-placeholder.svg"
			alt="Some dummy alternative text for image."
			width="1600"
			height="800"
		/>
	</picture>
	<figcaption class="b-promo_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Explore More Possibilities and other thing into long long title with some additional details
		</h2>
		<p class="b-promo_caption-subtitle">
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app. <br/>
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app. <br/>
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app. <br/>
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app. <br/>
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app. <br/>
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app. <br/>
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app. <br/>
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app. <br/>
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app. <br/>
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app. <br/>
		</p>
		<div class="b-promo_caption-actions">
			<a
				class="b-button"
				href="$url('Search-Show', 'cgid', 'category-2')$"
			>
				Shop Now
			</a>
		</div>
	</figcaption>
</figure>
```

### Different aspect ratio of image

```html_example
<figure class="b-promo_box">
	<picture class="b-promo_box-picture" style="padding-bottom:10%">
		<img
			loading="lazy"
			src="../images/guide/examples/image-placeholder.svg"
			alt="Some dummy alternative text for image."
			width="1600"
			height="800"
		/>
	</picture>
	<figcaption class="b-promo_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Explore More Possibilities
		</h2>
		<p class="b-promo_caption-subtitle">
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app.
		</p>
		<div class="b-promo_caption-actions">
			<a
				class="b-button"
				href="$url('Search-Show', 'cgid', 'category-2')$"
			>
				Shop Now
			</a>
		</div>
	</figcaption>
</figure>
```

## Horizontal alignment

### `m-caption_left`

```html_example
<figure class="b-promo_box m-caption_left">
	<picture class="b-promo_box-picture">
		<img
			loading="lazy"
			src="../images/guide/examples/image-placeholder.svg"
			alt="Some dummy alternative text for image."
			width="1600"
			height="800"
		/>
	</picture>
	<figcaption class="b-promo_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Explore More Possibilities
		</h2>
		<div class="b-promo_caption-actions">
			<a
				class="b-button"
				href="$url('Search-Show', 'cgid', 'category-2')$"
			>
				Shop Now
			</a>
		</div>
	</figcaption>
</figure>
```

### `m-caption_right`

```html_example
<figure class="b-promo_box m-caption_right">
	<picture class="b-promo_box-picture">
		<img
			loading="lazy"
			src="../images/guide/examples/image-placeholder.svg"
			alt="Some dummy alternative text for image."
			width="1600"
			height="800"
		/>
	</picture>
	<figcaption class="b-promo_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Explore More Possibilities
		</h2>
		<div class="b-promo_caption-actions">
			<a
				class="b-button"
				href="$url('Search-Show', 'cgid', 'category-2')$"
			>
				Shop Now
			</a>
		</div>
	</figcaption>
</figure>
```

### `m-caption_center`

```html_example
<figure class="b-promo_box m-caption_center">
	<picture class="b-promo_box-picture">
		<img
			loading="lazy"
			src="../images/guide/examples/image-placeholder.svg"
			alt="Some dummy alternative text for image."
			width="1600"
			height="800"
		/>
	</picture>
	<figcaption class="b-promo_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Explore More Possibilities
		</h2>
		<div class="b-promo_caption-actions">
			<a
				class="b-button"
				href="$url('Search-Show', 'cgid', 'category-2')$"
			>
				Shop Now
			</a>
		</div>
	</figcaption>
</figure>
```

### `m-caption_offcenter`

```html_example
<figure class="b-promo_box m-caption_offcenter">
	<picture class="b-promo_box-picture">
		<img
			loading="lazy"
			src="../images/guide/examples/image-placeholder.svg"
			alt="Some dummy alternative text for image."
			width="1600"
			height="800"
		/>
	</picture>
	<figcaption class="b-promo_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Explore More Possibilities
		</h2>
		<div class="b-promo_caption-actions">
			<a
				class="b-button"
				href="$url('Search-Show', 'cgid', 'category-2')$"
			>
				Shop Now
			</a>
		</div>
	</figcaption>
</figure>
```

## Vertical alignment

For sake of simpleness and robustness Boilerplate do not provide predefined vertical alignment
variations.

## Video as base

It is not limited what you could use as base for banner - it could be image or video.

What you need to do:

* `autoplay loop muted playsinline` is required to auto play video without user gesture in iOS

Please see [iOS manual](https://webkit.org/blog/6784/new-video-policies-for-ios/)

```html_example
<figure class="b-promo_box">
	<div class="b-promo_box-picture">
		<video autoplay loop muted playsinline width="1600" height="800">
			<source src="../../images/guide/examples/banner-video-16x9-lg.mp4" type="video/mp4" />
			<source src="../../images/guide/examples/banner-video-16x9-lg.mov" type="video/quicktime" />
		</video>
	</div>
	<figcaption class="b-promo_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Explore More Possibilities
		</h2>
		<div class="b-promo_caption-actions">
			<a
				class="b-button"
				href="$url('Search-Show', 'cgid', 'category-2')$"
			>
				Shop Now
			</a>
		</div>
	</figcaption>
</figure>
```

## Advanced: tiled background

```html_example
<style>
	.b-promo_box.m-image_3x4_right {
		.b-promo_box-picture {
			grid-column: 8 / span 4;
			padding-bottom: aspect-ratio(3, 4);
			padding-bottom: 133.3333333333%;
		}
	}
	.b-promo_box.m-image_3x4_left {
		.b-promo_box-picture {
			grid-column: 1 / span 4;
			padding-bottom: aspect-ratio(3, 4);
			padding-bottom: 133.3333333333%;
		}
	}
</style>
<figure class="b-promo_box m-caption_left m-image_3x4_right m-text_below">
	<picture class="b-promo_box-picture">
		<img
			loading="lazy"
			src="../images/guide/examples/banner-3x4-5.jpg?$staticlink$"
			alt="Some dummy alternative text for image."
			width="1600"
			height="800"
		/>
	</picture>
    <figcaption class="b-promo_box-caption b-promo_caption">
        <h2 class="b-promo_caption-title">
            New beauty neutrals color
        </h2>
        <p class="b-promo_caption-subtitle">
            Plunge into calm pastel colors, choose for yourself only the most sophisticated and beautiful attire in the new season
        </p>
        <div class="b-promo_caption-actions">
            <a
                class="b-button"
                href="$url('Search-Show', 'cgid', 'category')$"
            >
                Shop New Season
            </a>
        </div>
    </figcaption>
</figure>
```

## Advanced: Background stretched independent from container

```html_example
<figure class="b-promo_box m-full_bleed m-caption_offcenter">
	<picture class="b-promo_box-picture">
		<source type="image/jpeg" media="(max-width: 767px)"
				srcset="../images/guide/examples/banner-16x9-sm.jpg?$staticlink$, ../images/guide/examples/banner-16x9-sm@2x.jpg?$staticlink$ 2x" />
		<source type="image/jpeg" media="(min-width: 768px) and (max-width: 1024px)"
				srcset="../images/guide/examples/banner-16x9-md.jpg?$staticlink$, ../images/guide/examples/banner-16x9-md@2x.jpg?$staticlink$ 2x" />
		<source type="image/jpeg" media="(min-width: 1025px)"
				srcset="../images/guide/examples/banner-16x9-lg.jpg?$staticlink$, ../images/guide/examples/banner-16x9-lg@2x.jpg?$staticlink$2x" />
		<img
			loading="lazy"
			src="../images/guide/examples/banner-16x9-lg.jpg?$staticlink$"
			alt="Some dummy alternative text for image."
			width="1600"
			height="800"
		/>
	</picture>
    <figcaption class="b-promo_box-inner">
        <div class="b-promo_box-caption b-promo_caption">
            <h2 class="b-promo_caption-title">
                Make boilerplate better
            </h2>
            <p class="b-promo_caption-subtitle">
                Everyone's fallen for boilerplate so we added to her games repertoire for spring with new playful styles
                inspired by darts and that staple of a British pub - the fruit machine.
            </p>
            <div class="b-promo_caption-actions">
                <a
                    class="b-button"
                    href="$url('Search-Show', 'cgid', 'category')$"
                >
                    Shop New Season
                </a>
            </div>
        </div>
    </figcaption>
</figure>
```
*/

// Could be
// .b-promo_box ?m-text_below | m-text_over
//     .b-promo_img ?m-hero | m-2x1 | m-3x4 | m-left | m-right
//     .b-promo_caption ?m-center | m-left | m-right | m-offcenter
.b-promo_box {
	$root: &;

	@include g-grid;

	&-link {
		display: block;

		@include hover-supported {
			&:hover {
				text-decoration: none;
			}
		}
	}

	&-picture {
		@include g-image_container(_container, aspect-ratio(2.26, 1));

		grid-column: grid-start / grid-end;
		grid-row: 1 / 2;

		@include media(sm) {
			padding-bottom: aspect-ratio(1, 1);
		}

		img,
		video {
			@include g-image_container(_img);
		}
	}

	&-inner {} // reserved for hero banner

	&-caption {
		grid-column: grid-start / grid-end;
		grid-row: 1 / 2;
		padding: 30px 0;
		position: relative;
		text-align: center;
	}

	// Modifications
	&.m-full_bleed {
		grid-template-columns: auto; // Altering hero because it full-bleed banner and move grid to `-inner` element
		height: 100%;

		.b-promo_box-picture {
			grid-column: 1 / 2;
			grid-row: 1 / 2;
			padding-bottom: aspect-ratio(1442, 466); // Hero has unique logic for aspect ratios, because it should tease below the fold content

			@include media(md) {
				padding-bottom: aspect-ratio(768, 448);
			}

			@include media(sm) {
				padding-bottom: aspect-ratio(375, 432);
			}
		}

		.b-promo_box-inner {
			@include g-grid;

			grid-column: 1 / 2;
			grid-row: 1 / 2;
			margin-bottom: 15px; // space for HP hero pagination
			margin-top: 15px; // space for HP hero pagination
			width: 100%;

			@include media(sm) {
				padding: 0 15px;
			}
		}
	}

	&.m-hero_carousel {
		grid-template-columns: auto; // Altering hero because it full-bleed banner and move grid to `-inner` element
		height: 100%;

		.b-promo_box-picture {
			grid-column: 1 / 2;
			grid-row: 1 / 2;
			padding-bottom: aspect-ratio(1442, 466); // Hero has unique logic for aspect ratios, because it should tease below the fold content

			@include media(md) {
				padding-bottom: aspect-ratio(768, 448);
			}

			@include media(sm) {
				padding-bottom: aspect-ratio(375, 264);

				.b-hero_carousel.m-one_item & {
					padding-bottom: aspect-ratio(375, 224);
				}
			}
		}

		.b-promo_box-inner {
			@include g-grid;

			grid-column: 1 / 2;
			grid-row: 1 / 2;
			margin-bottom: 15px; // space for HP hero pagination
			margin-top: 15px; // space for HP hero pagination
			width: 100%;

			@include media(sm) {
				padding: 0 35px;
			}
		}
	}

	&:not(.m-full_bleed) {
		.b-promo_box-caption {
			@include media(sm) {
				padding-left: grid-margin(sm);
				padding-right: grid-margin(sm);
			}
		}
	}

	// Caption modifications
	&.m-caption_left {
		.b-promo_box-caption {
			text-align: left;

			@include media(md-up) {
				grid-column: var(--column-start, 2) / span var(--column-end, 5);
			}
		}
	}

	&.m-caption_right {
		.b-promo_box-caption {
			text-align: left;

			@include media(md-up) {
				grid-column: 8 / span 5;
			}
		}
	}

	&.m-caption_center {
		.b-promo_box-caption {
			@include media(md-up) {
				grid-column: 3 / span 8;
			}
		}
	}

	&.m-caption_offcenter {
		.b-promo_box-caption {
			text-align: left;

			@include media(md-up) {
				grid-column: 7 / span 5;
			}
		}
	}

	&.m-caption_top {
		.b-promo_box-caption {
			align-self: start;
		}
	}

	&.m-caption_middle {
		.b-promo_box-caption {
			align-self: center;
		}
	}

	&.m-caption_bottom {
		.b-promo_box-caption {
			align-self: end;
		}
	}

	&.m-caption_top-sm {
		.b-promo_box-caption {
			@include media(sm) {
				align-self: start;
			}
		}
	}

	&.m-caption_middle-sm {
		.b-promo_box-caption {
			@include media(sm) {
				align-self: center;
			}
		}
	}

	&.m-caption_bottom-sm {
		.b-promo_box-caption {
			@include media(sm) {
				align-self: end;
			}
		}
	}

	&.m-actions_columns {
		@include media(md-up) {
			--actions-columns: 2;
		}
	}

	&.m-actions_columns-sm {
		@include media(sm) {
			--actions-columns: 2;
		}
	}

	&.m-actions_width {
		.b-promo_caption-actions {
			grid-auto-columns: minmax(250px, max-content);
			grid-template-columns: repeat(var(--actions-columns), minmax(var(--actions-btn-width, 200px), max-content));
	
			@include media(lg-down) {
				--actions-btn-width: 150px;
			}
		}
	}

	&.m-actions_center {
		--actions-width: fit-content;
		--column-start: 1;

		.b-promo_box-caption {
			@include media(md-up) {
				padding-inline: 20px;
			}

			@include media(md) {
				--column-end: 6;
			}

			@include media(lg-up) {
				max-width: 90%;
			}
		}
	}

	// Layouts modifications

	// &.text_over {} default
	&.m-text_below:not(.m-full_bleed) {
		.b-promo_box-picture {
			@include media(sm) {
				grid-column: grid-start / grid-end;
			}
		}

		.b-promo_box-caption {
			@include media(sm) {
				grid-column: grid-start / grid-end;
				grid-row: 2 / 3;
				padding: 15px 0;
			}
		}
	}

	&.m-text_below.m-full_bleed { // When text_below inside full_bleed we need to alter
		@include media(sm) {
			display: block;

			.b-promo_box-picture {
				grid-column: grid-start / grid-end;
			}

			.b-promo_box-inner {
				grid-column: grid-start / grid-end;
				grid-row: 2 / 3;
			}

			.b-promo_box-caption {
				padding: 15px 0;
			}
		}
	}

	&.m-top-banner {
		color: $color-primary;
		line-height: 1.25;

		#{$root}-picture {
			border-radius: $global-radius;
			max-height: 120px;
			padding-bottom: 0;

			img {
				position: static;
			}
		}

		#{$root}-caption {
			align-self: center;
			padding: 0;
		}

		.b-promo_caption-title {
			color: $color-primary;
			font-size: 24px;
			font-weight: 700;
			margin-bottom: 0;
		}

		.b-promo_caption-subtitle {
			font-size: 13px;
			font-weight: 600;
			margin-bottom: 5px;

			@include media(md-up) {
				font-size: 15px;
			}
		}
	}

	&.m-account {
		color: $color-bg;

		#{$root}-picture {
			border-radius: $global-radius;
			padding-bottom: 120px;
		}

		#{$root}-caption {
			align-self: center;
			padding: 0;
		}

		.b-promo_caption-title {
			@include g-heading_4;

			color: $color-bg;
			margin-bottom: 0;
		}

		.b-promo_caption-subtitle {
			font-size: 15px;
			font-weight: 60;
		}
	}

	&.m-promo-banner {
		border-radius: $global-radius;
		box-shadow: $depth-1;

		#{$root}-caption {
			padding: 20px;
			text-align: left;

			@include media(lg-up) {
				padding: 20px 28px;
			}
		}

		#{$root}-picture {
			border-radius: $global-radius;
			padding-bottom: 100px;

			@include media(lg-up) {
				padding-bottom: 110px;
			}
		}

		.b-promo_caption-title {
			color: $color-text-promo;
			font-size: 20px;
			font-weight: 700;
			line-height: 1.25;

			@include media(md-up) {
				font-size: 24px;
			}
		}

		.b-promo_caption-actions {
			font-size: 13px;
			font-weight: 600;
			line-height: 1;
			text-decoration: underline;

			@include media(lg-up) {
				font-size: 15px;
			}
		}
	}

	&.m-storelocator {
		#{$root}-picture {
			border-radius: $global-radius;
			padding-bottom: 352px;
		}

		#{$root}-caption {
			@include media(md-down) {
				align-self: stretch;
				display: flex;
				flex-direction: column;
				padding: 10px 0 15px;
			}

			@include media(lg-up) {
				align-self: start;
			}
		}

		.b-promo_caption-subtitle {
			font-size: 15px;
			font-weight: 600;

			@include media(md-down) {
				margin-bottom: 6px;
			}
		}

		.b-promo_caption-title {
			font-size: 24px;

			@include media(lg-up) {
				font-size: 32px;
			}
		}

		.b-promo_caption-actions {
			@include media(md-down) {
				margin-top: auto;
				padding-top: 25px;
			}
		}
	}

	&.m-confirmation {
		#{$root}-picture {
			border-radius: $global-radius;
			padding-bottom: 120px;
		}
	}
}
