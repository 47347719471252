.b-large_item {
	&-badge {
		border: 1px solid $color-burgundy;
		border-radius: 2px;
		color: $color-burgundy;
		display: inline-block;
		font-size: 11px;
		line-height: 1.5;
		margin-bottom: 5px;
		padding: 2px 8px;
	}
}
