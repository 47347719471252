.b-idea_dialog {
	&-main {
		@include media(lg-up) {
			padding-top: 15px;
		}
	}

	&-top {
		margin-bottom: 20px;

		@include media(lg-up) {
			display: flex;
			margin-bottom: 20px;
		}
	}

	&-image {
		margin: 0 -16px 15px;

		@include media(md-up) {
			margin: 0 -20px 18px;
		}

		@include media(lg-up) {
			margin: 0 30px 0 -20px;
			min-width: 316px;
		}
	}

	&-details {
		position: relative;
	}

	&-bottom {
		display: flex;
		margin-top: 15px;

		@include media(sm) {
			background-color: rgba($color-bg, 0.8);
			bottom: calc(100% + 15px);
			margin: 0 -16px;
			padding: 14px 17px;
			position: absolute;
			width: calc(100% + 32px);
		}

		.b-button {
			text-transform: capitalize;
		}
	}

	.b-product_details {
		&-name {
			color: $color-title;
			font-size: 17px;
			font-weight: 600;
			margin-bottom: 16px;

			@include media(sm) {
				@include line-clamping($lines: 2);

				max-height: 42px;
			}

			@include media(lg-up) {
				font-size: 20px;
			}
		}

		&-share {
			margin-right: 15px;

			@include media(lg-up) {
				min-width: 130px;
			}
		}

		&-description {
			@include media(sm) {
				font-size: 13px;
			}
		}

		&-attributes {
			margin-bottom: 15px;
		}

		&-attribute_label {
			@include media(sm) {
				font-size: 11px;
				margin-bottom: 0;
			}
		}

		&-attribute_icon {
			color: $color-action-obj;

			svg {
				height: 13px;
				width: 13px;

				@include media(lg-up) {
					height: 16px;
					width: 16px;
				}
			}
		}
	}

	.b-product_slider-ctrl[disabled] {
		visibility: hidden;
	}

	.b-product_share {
		font-size: 15px;
		left: 0;
		margin-bottom: 12px;
		min-width: 214px;
		right: auto;

		.b-wishlist_button.m-simple {
			font-size: 15px;
		}
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}
}
