.b-search_noresults {
	overflow: hidden;
	position: relative;

	@include media (sm) {
		margin-bottom: 45px;
	}

	&-bg {
		height: 202px;
		left: -25%;
		position: absolute;
		top: -6%;
		width: 202px;

		@include media (md-up) {
			height: 329px;
			left: -9%;
			top: -14%;
			width: 329px;
		}

		path {
			fill: $color-bg-search-circle;
		}
	}

	&-wrapper {
		align-items: center;
		background: $color-bg-search-result;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 76px 28px 51px;
		width: 100%;

		@include media (md-up) {
			padding: 56px 0;
		}
	}

	&-header {
		line-height: 1.5;
		z-index: 2;
	}

	&-title {
		color: $color-title;
		font-size: 15px;
		font-weight: normal;
		margin-bottom: 18px;
		text-align: center;

		@include media (md-up) {
			font-size: 17px;
		}
	}

	&-description {
		color: $color-primary;
		margin: 0 auto 20px;
		max-width: 251px;
		text-align: center;

		@include media (md-up) {
			max-width: 454px;
		}
	}

	&-form {
		margin: 0 auto;
		max-width: 290px;

		@include media (md-up) {
			max-width: 425px;
		}

		&::after {
			display: none;
		}
	}

	&-recommendations {
		margin: auto;
		max-width: 566px;
		padding-bottom: 40px;

		@include media (sm) {
			padding: 0 45px;
		}
	}

	&-products {
		margin-top: 41px;
	}

	&-value {
		font-size: 20px;
		font-weight: 700;
		line-height: 25px;
	}

	.b-plp_no_results & {
		overflow: visible;

		&-bg {
			height: 197px;
			left: 0;
			top: 0;
			width: 197px;

			@include media(sm) {
				left: -25%;
			}
		}

		&-wrapper {
			overflow: hidden;
			position: relative;

			@include media(sm) {
				padding: 35px;
			}
		}
	}

	.l-section {
		margin-top: 60px;
	}
}
