$slot-border-radius: 0 20px 20px 0;
$slot-border-radius-mobile: 20px 20px 2px 2px;

.b-plp_grid_slot {
	@include g-loader;

	display: flex;
	flex-direction: column;
	grid-column: span 2;
	justify-content: space-between;
	position: relative;
	transition: opacity $motion-ease;

	@include media(sm) {
		min-height: 350px;

		.l-plp_grid.m-one_column & {
			grid-column: span 1;
		}
	}

	&::before {
		opacity: 0.95;
		z-index: 2;
	}

	&::after {
		opacity: 1;
		z-index: 2;
	}

	&.m-single {
		grid-column: span 1;
		grid-column-end: 5;

		@include media(sm) {
			grid-column-end: 3;
		}
	}

	&.m-grey {
		background-color: $color-bg-plp-slot-grey;
	}

	&.m-inited {
		&::after,
		&::before {
			display: none;
		}
	}

	&-btn {
		background-color: $color-burgundy;
		border-color: $color-burgundy;
		color: $color-white;
		margin: 0 20px 20px auto;
		max-width: 180px;
		padding: 0 30px;
		z-index: 1;

		&:hover {
			background-color: $color-burgundy-dark1;
		}

		&.m-outline {
			background-color: $color-burgundy;
			border-color: $color-burgundy;
			color: $color-white;

			&:hover {
				background-color: $color-burgundy-dark1;
			}
		}
	}

	&.m-single &-btn {
		margin: 0;
		max-width: none;
		padding: 0;
	}

	&.m-single &-btn.b-button {
		display: none;
	}

	&.m-dark &-btn {
		background-color: $color-natural;
		border-color: $color-natural;
		color: $color-burgundy;

		&:hover {
			background-color: $color-burgundy;
			border-color: $color-burgundy;
			color: $color-natural;
		}

		&.m-outline {
			background-color: $color-natural;
			border-color: $color-natural;
			color: $color-burgundy;

			&:hover {
				background-color: $color-burgundy;
				border-color: $color-burgundy;
				color: $color-natural;
			}
		}
	}

	&-content {
		background-color: rgba($color-natural, 0.75);
		border-radius: $slot-border-radius;
		color: $color-plp-slot;
		left: 0;
		margin: 0 !important; // stylelint-disable-line
		max-height: 181px;
		max-width: 266px;
		padding: 16px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: 1;
	}

	&.m-dark &-content {
		background-color: rgba($color-plp-slot, 0.75);
		color: $color-natural;
	}

	&.m-single &-content {
		background: none;
		max-width: none;
		position: static;
		text-align: center;
		transform: none;
		width: 100%;
	}

	&-title {
		@include g-heading_3;

		color: $color-plp-slot !important; // stylelint-disable-line
		display: inline;
		line-height: 1.2;
		max-width: none !important; // stylelint-disable-line

		.m-inited & {
			@include line-clamping($lines: 3);
		}
	}

	&.m-single &-title {
		@include g-heading_5;

		background-color: rgba($color-natural, 0.75);
		border-radius: $slot-border-radius-mobile;
		bottom: 0;
		color: $color-plp-slot !important; // stylelint-disable-line
		left: 0;
		max-height: 86px;
		padding: 8px;
		position: absolute;
		text-align: center;
		top: auto;
		width: 100%;
		z-index: 1;
	}

	&.m-dark &-title {
		color: $color-natural !important; // stylelint-disable-line
	}

	&.m-single.m-dark &-title {
		background-color: rgba($color-plp-slot, 0.75);
	}

	&-subtitle {
		font-size: 13px;
		font-weight: 500;
		line-height: 16px;
	}

	&-img {
		border-radius: 2px;
		height: 100%;
		object-fit: cover;
		overflow: hidden;
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
		z-index: -1;

		.g-image {
			min-width: 100%;
		}
	}

	// stylelint-disable
	.g-badge {
		display: none;
		font-size: 18px !important;
		line-height: 1.25 !important;
		margin: 0 0 3px !important;
		max-width: none;
		position: static;
		white-space: nowrap;
	}
	// stylelint-enable
	&.m-inited .g-badge {
		display: inline-block;
	}

	&.m-single .g-badge {
		font-size: 13px !important; // stylelint-disable-line
		left: 0;
		margin: 6px !important; // stylelint-disable-line
		padding: 4px 8px 3.75px;
		position: absolute;
		top: 0;
		z-index: 1;
	}
}
