/*md

# b-promo_caption

Promo caption is the content of promo components.

## Structure

`b-promo_caption` consist from 3 main elements:

* title
* subtitle
* actions container

All of this elements are optional.

## Variation

For sake of simpleness and robustness Boilerplate do not provide predefined sizes and styles
variations.

## Examples

### All elements

```html_example
<div class="b-promo_caption">
	<h2 class="b-promo_caption-title">
		Shop Now. Pay Later.
		Exclusively for Members
	</h2>
	<p class="b-promo_caption-subtitle">
		Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app.
	</p>
	<div class="b-promo_caption-actions">
		<a
			class="b-button m-outline"
			href="$url('Search-Show', 'cgid', 'category-2')$"
		>
			Activate
		</a>
	</div>
</div>
```

### Different order

```html_example
<div class="b-promo_caption">
	<p class="b-promo_caption-subtitle">
		Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app.
	</p>
	<h2 class="b-promo_caption-title">
		Shop Now. Pay Later.
		Exclusively for Members
	</h2>
	<div class="b-promo_caption-actions">
		<a
			class="b-button m-outline"
			href="$url('Search-Show', 'cgid', 'category-2')$"
		>
			Activate
		</a>
	</div>
</div>
```

### Only title and CTA

```html_example
<div class="b-promo_caption">
	<h2 class="b-promo_caption-title">
		Shop Now. Pay Later.
	</h2>
	<div class="b-promo_caption-actions">
		<a
			class="b-button m-outline"
			href="$url('Search-Show', 'cgid', 'category-2')$"
		>
			Join
		</a>
	</div>
</div>
```

### 2 CTA

```html_example
<div class="b-promo_caption">
	<h2 class="b-promo_caption-title">
		New spring collections
	</h2>
	<div class="b-promo_caption-actions">
		<a
			class="b-button m-outline"
			href="$url('Search-Show', 'cgid', 'category-2')$"
		>
			Men
		</a>
		<a
			class="b-button m-outline"
			href="$url('Search-Show', 'cgid', 'category-2')$"
		>
			Woman
		</a>
	</div>
</div>
```

### 3 CTA

```html_example
<div class="b-promo_caption">
	<h2 class="b-promo_caption-title">
		New spring collections
	</h2>
	<div class="b-promo_caption-actions">
		<a
			class="b-button m-outline"
			href="$url('Search-Show', 'cgid', 'category-2')$"
		>
			Men
		</a>
		<a
			class="b-button"
			href="$url('Search-Show', 'cgid', 'category-2')$"
		>
			Woman
		</a>
		<a
			class="b-button m-link"
			href="$url('Search-Show', 'cgid', 'category-2')$"
		>
			Kids
		</a>
	</div>
</div>
```

### Without CTA

```html_example
<a
	class="b-promo_caption"
	href="$url('Search-Show', 'cgid', 'category-2')$"
>
	<h2 class="b-promo_caption-title">
		New spring collections
	</h2>
	<p class="b-promo_caption-subtitle">
		Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app.
	</p>
</a>
```

## Example in component

### b-promo_info_box

```html_example
<div class="b-promo_info_box">
	<div class="b-promo_info_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Shop Now. Pay Later. <br/>
			Exclusively for Members
		</h2>
		<p class="b-promo_caption-subtitle">
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app.
		</p>
		<div class="b-promo_caption-actions">
			<a class="m-outline b-button" href="$url('Search-Show', 'cgid', 'category-2')$">
				Join today
			</a>
		</div>
	</div>
</div>
```

### b-promo-box

```html_example
<figure class="b-promo_box">
	<picture class="b-promo_box-picture">
		<source type="image/jpeg" media="(max-width: 767px)"
				srcset="../images/guide/examples/banner-16x9-sm.jpg?$staticlink$, ../images/guide/examples/banner-16x9-sm@2x.jpg?$staticlink$ 2x" />
		<source type="image/jpeg" media="(min-width: 768px) and (max-width: 1024px)"
				srcset="../images/guide/examples/banner-16x9-md.jpg?$staticlink$, ../images/guide/examples/banner-16x9-md@2x.jpg?$staticlink$ 2x" />
		<source type="image/jpeg" media="(min-width: 1025px)"
				srcset="../images/guide/examples/banner-16x9-lg.jpg?$staticlink$, ../images/guide/examples/banner-16x9-lg@2x.jpg?$staticlink$2x" />
		<img
			loading="lazy"
			src="../images/guide/examples/banner-16x9-lg.jpg?$staticlink$"
			alt="Some dummy alternative text for image."
			width="1600"
			height="800"
		/>
	</picture>
	<figcaption class="b-promo_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Explore More Possibilities
		</h2>
		<p class="b-promo_caption-subtitle">
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app.
		</p>
		<div class="b-promo_caption-actions">
			<a
				class="b-button"
				href="$url('Search-Show', 'cgid', 'category-2')$"
			>
				Shop Now
			</a>
		</div>
	</figcaption>
</figure>
```

*/

.b-promo_caption {
	align-self: center;

	&-title {
		@include g-heading_3;

		color: $color-title;
		margin-bottom: 15px;

		@include media(lg) {
			font-size: 40px;
		}

		@include media(xl) {
			font-size: 48px;
		}

		.b-promo_box.m-confirmation & {
			font-size: 24px;
			margin-bottom: 0;
		}
	}

	&-subtitle {
		font-size: 20px;
		font-weight: 700;

		@include media(lg-up) {
			font-size: 24px;
		}

		.b-promo_box.m-confirmation & {
			font-size: 15px;
		}
	}

	&-description {
		font-size: 13px;
		max-width: 330px;

		@include media(lg-up) {
			font-size: 15px;
		}
	}

	&-actions {
		display: grid;
		gap: 16px 8px;
		grid-auto-columns: max-content;
		grid-template-columns: repeat(var(--actions-columns), max-content);
		margin: 0 auto;
		max-width: var(--actions-width, auto);

		&:not(:first-child) {
			margin-top: 25px;
		}

		.b-button {
			white-space: normal;
			word-break: break-word;
		}
	}
}
