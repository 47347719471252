.b-refinements_panel {
	@include media(lg-up) {
		padding-top: 20px;

		.m-hybrid & {
			padding-top: 0;
		}

		.b-slide_panel-action {
			display: none;
		}
	}

	&-title {
		color: $color-title;
		padding-bottom: 16px;

		@include media(md-down) {
			align-items: center;
			background-color: $color-info-obj;
			display: flex;
			min-height: 60px;
			padding: 0 16px;
		}

		@include media(lg-up) {
			button {
				display: none;
			}
		}

		h2 {
			flex-grow: 1;
			font-size: 17px;
			font-weight: 600;
		}
	}

	&-sticky_wrapper {
		height: 100%;
		position: relative;
	}

	&-sticky {
		display: flex;
		flex-direction: column;
		max-height: calc(100vh - 60px);
		overflow-x: hidden;
		overflow-y: scroll;
		position: sticky;
		top: 60px;

		@include media(lg-up) {
			max-height: calc(100vh - 101px);
			top: 101px;

			&::-webkit-scrollbar {
				background-color: $color-bg;
				width: 5px;
			}

			&::-webkit-scrollbar-thumb {
				background-color: $color-scrollbar-thumb;
				border-radius: 8px;
			}
		}
	}

	&.m-busy {
		cursor: wait;
		pointer-events: none;

		.b-refinements_panel-content {
			opacity: 0.6;
		}
	}
}
