.b-product_gallery {
	$carousel-controls-space: 82px;
	$thumbs-width: 118px;

	position: relative;

	&-inner {
		display: flex;

		@include media(md-down) {
			flex-direction: column-reverse;
		}

		.m-alternative-gallery & {
			@include media(lg-up) {
				align-items: flex-start;
			}
		}
	}

	&-image_wrap {
		display: block;
	}

	&-zoom {
		color: $color-bg-shade;
		font-size: 15px;
		font-weight: 500;
		position: absolute;
		text-align: center;
		z-index: 1;

		@include media(sm) {
			bottom: 16px;
			right: 14px;
		}

		@include media(md-down) {
			.l-pdp.m-idea & {
				bottom: auto;
				right: 6px;
				top: 6px;
			}
		}

		@include media(lg-up) {
			background-color: rgba($color-bg-shade, 0.8);
			border-radius: 40px;
			color: $color-primary;
			left: 50%;
			opacity: 0;
			padding: 5px 14px;
			top: 50%;
			transform: translate(-50%, -50%);
			transition: opacity $motion-fast;

			.b-carousel-item:hover &,
			.b-product_slider-item:hover & {
				opacity: 1;
			}
		}
	}

	&-thumbs {
		bottom: 0;
		height: 532px + $carousel-controls-space * 2;
		left: 40px;
		margin: auto 0;
		max-height: 100vh;
		min-width: $thumbs-width;
		overflow: hidden;
		padding: 1px 0; // prevent margin collapse
		pointer-events: none;
		position: fixed;
		top: 0;
		visibility: hidden;
		z-index: z(zoom-thumbs);

		@include media(md-down) {
			display: none;
		}

		&.m-zoomed-in {
			pointer-events: initial;
			visibility: visible;
		}

		.m-alternative-gallery &:not(.m-zoomed-in) {
			bottom: auto;
			left: auto;
			margin: 0 20px 0 0;
			pointer-events: all;
			position: relative;
			visibility: visible;
			z-index: 1;
		}
	}

	&-thumbs_track {
		@include g-snap_scroll($direction: y);

		height: 532px;
		max-height: calc(100vh - #{$carousel-controls-space * 2}); // Safari could not calculate 100% of parent so we need to pass it explicitly
	}

	&-thumb {
		background: $color-bg-shade2;
		cursor: pointer;
		height: 118px;
		min-height: 118px;
		min-width: $thumbs-width;
		position: relative;
		scroll-snap-align: center;
		transform: translateZ(0); // new stacking context to fix a render issue
		width: $thumbs-width;

		&::after {
			border: 2px solid transparent;
			border-radius: $global-radius;
			bottom: 0;
			content: '';
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			transition: $motion-ease;
			transition-property: box-shadow, border;
			z-index: 1;
		}

		&.m-current {
			&::after {
				border-color: $color-border-active;
			}
		}

		.m-alternative-gallery & {
			background: none;
			border: 1px solid $color-mid-grey;
			border-radius: 4px;
		}

		& + & {
			margin-top: 20px;
		}

		.m-alternative-gallery & + & {
			margin-top: 8px;
		}
	}

	&-thumbs_ctrl {
		border: none;
		height: $carousel-controls-space;
		left: 0;
		line-height: $carousel-controls-space;
		opacity: 0;
		position: relative;
		right: 0;
		width: 100%;
		z-index: 1;

		&::before {
			background-color: rgba($color-bg, 0.8);
			border: 1px solid $color-divider;
			border-radius: 100%;
			box-shadow: $depth-1;
			content: '';
			height: 40px;
			left: 50%;
			margin-left: -20px;
			margin-top: -20px;
			position: absolute;
			top: 50%;
			width: 40px;
		}

		.m-alternative-gallery & {
			display: none;
		}

		.b-product_gallery-thumbs.m-inited &:not([disabled]) {
			cursor: pointer;
			opacity: 1;

			&:hover {
				color: $color-action;
			}
		}

		&.m-next {
			bottom: 0;

			svg {
				transform: rotate(-90deg);
			}
		}

		&.m-prev {
			top: 0;

			svg {
				transform: rotate(90deg);
			}
		}
	}

	/* stylelint-disable-next-line no-descending-specificity */
	&-thumbs.m-zoomed-in &-thumbs_ctrl {
		display: block;
	}

	&-image {
		@include g-image_container(_img);

		.m-alternative-gallery & {
			height: calc(100% - 8px);
			inset: 4px;
			width: calc(100% - 8px);
		}
	}

	&-main {
		width: 100%;
	}

	&-idea {
		width: 100%;

		&::before {
			@include media(lg-up) {
				background: linear-gradient(90deg, transparent, white);
				content: '';
				height: 100%;
				pointer-events: none;
				position: absolute;
				right: 0;
				top: 0;
				transition: opacity $motion-ease;
				width: 122px;
				z-index: 2;
			}
		}

		&:not(.m-next_visible) {
			&::before {
				opacity: 0;
			}
		}

		.b-carousel-item {
			flex: 0 0 100%;
			position: relative;

			@include media(lg-up) {
				flex: 0 0 80%;
			}

			&[role='button'] {
				cursor: url('./images/zoom-in.png'), zoom-in;
			}
		}

		.b-carousel-pagination {
			display: flex;
			gap: 10px;
			transform: translateX(0%);
			transition: all 0.3s ease;

			&_wrap {
				bottom: 22px;
				left: 50%;
				max-width: 120px;
				overflow: hidden;
				position: absolute;
				right: 0;
				transform: translateX(-50%);
				width: fit-content;

				@include media(lg-up) {
					bottom: 28px;
				}
			}

			&_dot {
				cursor: pointer;
			}

			&_dots {
				fill: $color-mid-grey;

				&.m-current {
					fill: $color-burgundy;
				}

				&.m-leading {
					transform: scale(0.75);
				}
			}
		}

		&.m-no_scroll {
			.b-carousel-item {
				@include media(lg-up) {
					flex: 0 0 100%;
				}
			}
		}
	}
}
