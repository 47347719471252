.b-photoswipe {
	&-close {
		@include g-button_iconed;

		height: 20px;
		margin-left: auto;
		z-index: 1;

		@include media(md-down) {
			margin-right: 10px;
		}

		svg {
			height: 22px;
			width: 22px;
		}
	}

	&-info {
		display: flex;
		justify-content: space-between;
		margin: 15px 15px 0 0;
		z-index: 1;

		@include media(lg-up) {
			margin: 40px 40px 0 0;

			.b-zoom_info {
				display: none;
			}
		}

		@include media(sm) {
			width: 100%;

			.b-zoom_info-icon {
				display: none;
			}
		}
	}

	// Hide thumbs under the window when Photoswipe in Zoom-in mode
	&.pswp--zoomed-in { // stylelint-disable-line
		z-index: z(zoom-thumbs) + 1;
	}
}

// stylelint-disable
.pswp {
	display: none;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	overflow: hidden;
	-ms-touch-action: none;
	touch-action: none;
	z-index: z(modal);
	-webkit-text-size-adjust: 100%;
	-webkit-backface-visibility: hidden;
	outline: 0;
}
.pswp img {
	max-width: none;
}
.pswp--animate_opacity {
	opacity: .001;
	will-change: opacity;
	transition: opacity $motion-ease;
}
.pswp--open {
	display: block;
}
.pswp--zoom-allowed .pswp__img {
	cursor: url('./images/zoom-in.png'), zoom-in;
}
.pswp--zoomed-in .pswp__img {
	cursor: url('./images/zoom-out.png'), zoom-out;
}
.pswp--dragging .pswp__img {
	cursor: grabbing;
}
.pswp__bg {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: $color-bg;
	opacity: 0;
	transform: translateZ(0);
	-webkit-backface-visibility: hidden;
	will-change: opacity;
}
.pswp__scroll-wrap {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	height: 100%;
	overflow: hidden;

	@include media(lg-up) {
		left: 180px;
		right: 25px;
		transition: overflow 0s 1s linear;

		.pswp--zoomed-in & {
			overflow: visible;
			transition-delay: 0s;
		}
	}
}
.pswp__container,
.pswp__zoom-wrap {
	-ms-touch-action: none;
	touch-action: none;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}
.pswp__container,
.pswp__img {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
}
.pswp__zoom-wrap {
	position: absolute;
	width: 100%;
	transform-origin: left top;
	transition: transform $motion-ease;
}
.pswp__bg {
	will-change: opacity;
	transition: opacity $motion-ease;
}
.pswp--animated-in .pswp__bg,
.pswp--animated-in .pswp__zoom-wrap {
	-webkit-transition: none;
	transition: none;
}
.pswp__container,
.pswp__zoom-wrap {
	-webkit-backface-visibility: hidden;
}
.pswp__item {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	overflow: hidden;

	@include media(lg-up) {
		overflow: visible;
	}
}
.pswp__img {
	border-radius: 4px;
	position: absolute;
	width: auto;
	height: auto;
	top: 0;
	left: 0;
}
.pswp__img--placeholder {
	-webkit-backface-visibility: hidden;
}
.pswp__img--placeholder--blank {
	background: $color-bg-shade2;
}
.pswp--ie .pswp__img {
	width: 100% !important;
	height: auto !important;
	left: 0;
	top: 0;
}
.pswp__error-msg {
	position: absolute;
	left: 0;
	top: 50%;
	width: 100%;
	text-align: center;
	font-size: 14px;
	line-height: 16px;
	margin-top: -8px;
	color: $color-error;
}
.pswp__error-msg a {
	color: $color-error;
	text-decoration: underline;
}

.pswp__counter {
	display: none;
}

.pswp__button--arrow--left,
.pswp__button--arrow--right {
	background-color: rgba($color-white, 0.8);
	box-shadow: $depth-1;
	border: solid 1px $color-border-grey;
	border-radius: 100%;
	top: 50%;
	margin-top: -20px;
	width: 40px;
	height: 40px;
	position: absolute;

	@include media(lg-up) {
		display: none;
	}

	.b-icon_chevron {
		margin: auto;
	}
}

.pswp__button--arrow--left {
	left: 5px;

	.pswp--first & {
		display: none;
	}

	.b-icon_chevron {
		transform: rotate(90deg)
	}
}

.pswp__button--arrow--right {
	right: 5px;

	.pswp--last & {
		display: none;
	}

	.b-icon_chevron {
		transform: rotate(-90deg)
	}
}

.pswp__button--icon {
	pointer-events: none;
}

.pswp__video {
	align-items: center;
	display: flex;
	height: 100%;
	justify-content: center;
	margin: 0 auto;
	padding: 25px 0;
}
.pswp__video-player {
	max-width: calc(178vh - 90px);
	width: 100%;
}

// stylelint-enable
