.b-product_question {
	text-align: center;

	&-wrapper {
		padding: 25px 20px;

		@include media(md-up) {
			padding: 30px 40px 40px;
		}

		@include media(lg-up) {
			padding: 35px 45px 50px;
		}
	}

	&-icon {
		margin-bottom: 10px;
	}

	&-title {
		font-size: 24px;
		font-weight: 700;
		line-height: 1.25;
		margin-bottom: 25px;

		@include media(md-up) {
			margin-bottom: 40px;
		}

		@include media(lg-up) {
			font-size: 32px;
		}
	}

	.g-squiggle-icon {
		@include media(md-down) {
			svg {
				width: 70px;
			}
		}

		@include media(lg-up) {
			left: 42%;
		}
	}
}
