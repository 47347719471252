.b-search_promo {
	margin: 10px auto 0;
	width: 100%;

	@include media(xl) {
		max-width: 1418px;
		padding-left: 60px;
		padding-right: 60px;
	}
}
