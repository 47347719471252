.b-header_category {
	@include g-section_holder;

	margin-top: 20px;

	@include media(xl) {
		margin-top: 40px;
		max-width: $plp-content-width-max;
	}

	@include media(sm) {
		overflow: hidden;
	}

	&-title {
		@include g-heading_4;

		color: $color-title;

		.m-category-tiles & {
			text-align: center;
		}
	}

	&-description {
		color: $color-primary;
		font-size: 13px;
		line-height: 1.45;
		margin-bottom: 20px;
		margin-top: 10px;
		position: relative;

		&.m-cut-active {
			padding-bottom: 20px;
		}

		.m-category-tiles & {
			text-align: center;
		}

		.b-header_ext & {
			margin-top: -10px;
		}
	}

	&-description_content {
		@include line-clamping($lines: 1);

		max-height: 20px;

		@include media(lg-up) {
			@include line-clamping($lines: 2);

			max-height: 40px;
		}

		&.m-show-more {
			display: block;
			max-height: none;
		}
	}

	&-description_link {
		bottom: 0;
		color: $color-burgundy;
		font-weight: 600;
		left: 0;
		position: absolute;

		.m-category-tiles & {
			position: relative;
		}
	}
}
