.b-plp_hybrid {
	.b-product_tile {
		border-width: 0 0 1px;
		box-shadow: none;
		display: flex;
		padding-bottom: 20px;

		@include media(sm) {
			display: block;
			padding-bottom: 10px;
		}

		&-top {
			flex: 1 0 auto;
		}

		&-info {
			display: flex;
			flex-direction: column;
			min-width: 325px;
			padding: 0 0 0 10px;
			width: 325px;

			@include media(sm) {
				padding: 10px 0 0;
				width: auto;
			}

			&.m-single-image {
				min-width: none;
				width: auto;
			}
		}

		&-bottom {
			display: flex;
			flex: 1 0 auto;
			flex-direction: column;
			justify-content: space-between;
			padding-top: 5px;
			position: static;
		}

		&-cta {
			border-radius: 20px;
			margin-top: 15px;
		}

		&-bottom.m-single-image .b-product_tile-cta {
			@include media(xl) {
				max-width: 315px;
			}
		}

		&-bottom.m-single-image .b-product_details-full {
			@include media(xl) {
				bottom: 23px;
				max-width: none;
				position: absolute;
				right: 0;
			}
		}

		&-link {
			display: block;
			font-size: 24px;
			font-weight: 500;
			line-height: 1.25;
			margin-bottom: 10px;
			max-width: 460px;

			@include media(sm) {
				font-size: 15px;
			}
		}

		&-price {
			font-size: 24px;

			@include media(sm) {
				font-size: 13px;
				font-weight: 600;
			}
		}
	}

	.b-product_details {
		&-quantity_and_link {
			display: flex;
			justify-content: space-between;
		}

		&-quantity_label {
			font-size: 15px;
			padding: 5px 0;

			@include media(sm) {
				font-size: 13px;
			}
		}

		&-full {
			align-items: center;
			display: flex;
			float: right;
			font-size: 17px;
			line-height: 17px;
			margin-top: 35px;
			max-width: 130px;
			text-align: right;

			@include media(sm) {
				font-size: 13px;
				max-width: 170px;

				svg {
					width: 14px;
				}
			}
		}

		&-fulllink {
			font-weight: 600;
			text-decoration: underline;
		}
	}

	.b-stepper {
		&-button {
			border-width: 2px;
		}

		&-input {
			border-width: 2px 0;
		}
	}

	.b-wishlist_button {
		display: none;
	}
}
