.l-search {
	@include g-section_holder;

	margin-bottom: 75px;

	@include media(xl) {
		max-width: $plp-content-width-max;
	}

	&-content {
		position: relative;

		&::after {
			background-color: rgba($color-white, 0.6);
			bottom: 0;
			content: '';
			cursor: wait;
			left: 0;
			opacity: 0;
			position: absolute;
			right: 0;
			top: 0;
			transition: opacity $motion-ease;
			visibility: hidden;
		}

		&.m-busy::after {
			opacity: 1;
			visibility: visible;
		}
	}

	&-noresult {
		margin: 0 auto 25px;
		width: 100%;

		.l-section {
			@include g-section_holder;

			margin-top: 40px;
		}
	}

	&-seo {
		margin-bottom: 0;

		@include media(xl) {
			max-width: 1184px;
			padding: 0;
		}
	}
}
