.b-slide_panel {
	color: $color-action-obj;
	display: flex;
	height: 100%;
	transition: transform $motion-ease-popups;

	&.m-active_level_1 {
		transform: translateX(0);
	}

	&.m-active_level_2 {
		transform: translateX(-100%);
	}

	&-container {
		display: flex;
		flex-direction: column;
		min-width: 100%;
		overflow-y: auto;
	}

	&-submenu {
		height: calc(100% - 60px);
	}

	&-content {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: space-between;
	}

	&-container.m-level_1 &-content {
		display: none;
	}

	&-header {
		align-items: center;
		background-color: $color-info-obj;
		display: flex;
		min-height: 60px;
		padding: 0 16px;
	}

	&-header_action {
		width: 100%;
	}

	&-clear {
		color: $color-text-dimmed;
		cursor: pointer;
		display: flex;
		font-size: 15px;
		font-weight: 600;
		justify-content: center;
		text-align: center;
		text-decoration: underline;
		width: 100%;
	}

	&-title {
		font-size: 17px;
		font-weight: 600;
		padding: 0 15px;
		text-transform: capitalize;
	}

	&.m-active_level_1 &-title {
		padding-left: 0;
	}

	&-back,
	&-close_button {
		@include g-button_iconed;

		height: 22px;
		width: 22px;
	}

	&-item {
		align-items: center;
		box-shadow: 0 1px 0 0 $color-divider;
		cursor: pointer;
		display: flex;
		font-weight: 500;
		justify-content: space-between;
		padding: 13px 15px;
	}

	&-item_icon {
		@include g-button_iconed;

		height: 15px;
		width: 15px;
	}

	&-name {
		font-size: 15px;
		text-transform: capitalize;

		&.m-idea {
			text-transform: initial;
		}
	}

	&-action {
		background-color: $color-bg;
		bottom: 0;
		box-shadow: 0 -4px 16px 0 rgba($color-border-black, 0.15);
		margin-top: auto;
		padding: 0 15px;
		position: sticky;
	}

	.b-ideas_toggle,
	&-clear,
	&-close,
	&-apply {
		display: flex;
		margin: 15px 0;
	}
}
