.b-refinements_accordion {
	@include g-accordion;

	&-item {
		@include g-accordion(_item);

		border-top: 1px solid $color-border-grey;
	}

	&-button {
		@include g-accordion(_control);
	}

	&-title {
		color: $color-burgundy;
		font-size: 15px;
		text-transform: capitalize;

		@include media(lg-up) {
			font-size: 13px;
		}

		&_selections {
			color: $color-burgundy;
			font-size: 14px;

			@include media(lg-up) {
				display: none;
			}
		}

		&.m-idea {
			text-transform: initial;
		}
	}

	&-content {
		@include g-accordion(_content);

		height: 0;

		&[aria-hidden='false'] {
			@include g-accordion(_content, expanded);

			height: max-content;
		}

		// Make content accessible until JS is loaded
		.b-refinements_accordion-item:not([data-initialized='1']) & {
			@include g-accordion(_content, expanded);
		}
	}

	&-content_inner {
		@include g-accordion(_content_inner);
	}

	&-button,
	&-content_inner {
		@include media(lg-up) {
			padding-left: 8px;
			padding-right: 8px;
		}
	}
}
