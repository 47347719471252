.b-play_button {
	align-items: center;
	background-color: rgba($color-bg-overlay, 0.32);
	color: $color-white;
	cursor: pointer;
	display: flex;
	height: 100%;
	justify-content: center;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;

	&_gallery {
		background-color: rgba(0, 0, 0, 0.52);
		border-radius: 100%;
		height: auto;
		left: unset;
		margin-left: 50%;
		margin-top: 50%;
		padding: 1rem;
		top: unset;
		transform: translate(-50%, -50%);
		width: auto;
	}

	.b-product_gallery-thumb & {
		background-color: rgba($color-bg-overlay, 0.25);

		svg {
			height: 40px;
		}
	}

	.b-product_video-cover &,
	.b-video_content-cover & {
		background-color: transparent;

		svg {
			@include media(md-up) {
				height: 100px;
				width: 100px;
			}
		}
	}
}
