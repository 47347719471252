.b-applied_filters {
	&-items {
		display: flex;
		flex-wrap: wrap;
	}

	&-item {
		align-items: center;
		background-color: $color-bg-shade;
		border: $global-line solid $color-border-underline;
		border-radius: 2px;
		display: inline-flex;
		height: 31px;
		margin: 0 10px 10px 0;
		overflow: hidden;
		padding: 0 6px;
		user-select: none;

		&:hover {
			border-color: $color-action;
		}

		&.m-clear {
			background: none;
			border: none;
		}
	}

	&-clear_all {
		color: $color-primary;
		cursor: pointer;
		font-size: 13px;
		font-weight: 600;
		text-decoration: underline;

		&:hover {
			color: $color-action;
		}
	}

	&-value {
		align-items: center;
		display: inline-flex;
		line-height: 20px;
		margin-right: 8px;
		text-transform: capitalize;
	}

	&-rating_icon {
		color: $color-action;
		margin-left: 4px;
	}

	&-remove_button {
		color: $color-text-dimmed;
		cursor: pointer;
	}

	.b-plp_no_results & {
		&-title {
			display: none;
		}

		&-clear_all {
			color: $color-primary;
			font-size: 13px;
			font-weight: 600;
			line-height: 1.3;
			text-decoration: underline;
		}
	}
}
