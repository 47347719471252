/*md

# Refinement radio

TBD

*/

.b-refinement_radio {
	@include g-radio;

	background: transparent;
	box-shadow: none;
	cursor: pointer;

	&:hover {
		text-decoration: none;
	}

	&[aria-disabled='true'],
	&[aria-checked='true'],
	&.m-checked {
		pointer-events: none;
	}

	&-button {
		display: none;
	}

	&-icon {
		@include g-radio(_icon);

		.b-refinement_radio:active & {
			@include g-radio(_icon, m-active);
		}

		.b-refinement_radio:hover & {
			@include g-radio(_icon, m-hover);
		}

		.b-refinement_radio[aria-checked='true'] &,
		.b-refinement_radio.m-checked & {
			@include g-radio(_icon, m-checked);
		}

		.b-refinement_radio[aria-disabled='true'] & {
			@include g-radio(_icon, m-disabled);
		}
	}
}
