.b-content_item {
	&-title {
		font-size: 16px;
		font-weight: 700;
		margin-bottom: rh(3);
	}

	&-description {
		font-weight: 300;
		margin-bottom: rh(4);
	}

	&-link {
		@include g-link;

		font-weight: 500;
	}
}
