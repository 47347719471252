.b-plp_no_results {
	font-size: 16px;
	font-weight: 500;

	&-actions {
		margin-top: 24px;
	}

	&-inner {
		margin: 40px auto 60px;
		max-width: 814px;

		@include media(sm) {
			padding: 0 30px;
		}
	}

	&-title {
		color: $color-title;
		font-size: 20px;
		font-weight: bold;
		line-height: 25px;
		margin-bottom: 20px;
		text-align: center;
		z-index: 1;
	}

	&-subtitle {
		color: $color-primary;
		font-size: 15px;
		line-height: 22.5px;
	}

	&-description {
		color: $color-primary;
		font-size: 15px;
		line-height: 22.5px;
		margin: 40px auto 52px;
		max-width: 815px;
		text-align: center;
	}
}
