.b-product_accordion {
	@include g-accordion;

	margin-top: 25px;

	@include media(md-up) {
		margin-top: 45px;
	}

	&-item {
		@include g-accordion(_item);

		&:last-child {
			border-bottom: 1px solid $color-border-underline;
		}
	}

	&-title {
		border-top: 1px solid $color-border-underline;

		&.m-first {
			border: 0;
		}
	}

	&-button {
		@include g-accordion(_control);

		color: $color-text;
		justify-content: space-between;
		line-height: 1.5;
		padding: 15px 10px;

		&:hover {
			@include g-accordion(_control, hover);
		}
	}

	&-icon {
		[aria-expanded='true'] & {
			transform: rotate(180deg);
		}
	}

	&-content {
		@include g-accordion(_content);

		color: $color-primary;
		font-size: 15px;
		line-height: 1.5;

		&[aria-hidden='false'] {
			@include g-accordion(_content, expanded);
		}

		&[aria-hidden='true'] {
			height: 0;
		}

		// Make content accessible until JS is loaded
		.b-product_accordion-item:not([data-initialized='1']) & {
			@include g-accordion(_content, expanded);
		}
	}

	&-content_inner {
		@include g-accordion(_content_inner);

		font-size: 15px;
		font-weight: 400;
		padding-top: 8px;
	}

	&.m-questions &-button {
		@include media(md-up) {
			font-size: 17px;
		}
	}
}
