.b-header_search {
	@include g-section_holder_header;

	padding-top: 25px;
	text-align: center;

	@include media(lg-up) {
		padding-top: 30px;
	}

	&-title_no_results {
		@include g-heading_4;

		text-align: left;
		word-break: break-word;
	}

	&-no_products {
		margin-top: 12px;
	}

	&-title {
		align-items: center;
		display: flex;
		justify-content: center;
	}

	&-keywords {
		@include g-heading_5;

		color: $color-primary;
		display: inline-flex;
		quotes: '‘' '’';

		@include media(md-down) {
			font-size: 15px;
		}
	}

	&-keywords_count {
		@include g-heading_6;

		color: $color-text-dimmed;
		font-weight: 400;
		margin-left: 4px;

		@include media(md-down) {
			font-size: 15px;
		}
	}

	&-suggestion {
		font-size: 16px;
		margin-top: 16px;
		text-align: left;
	}
}
