.b-customer_service {
	margin-top: 24px;

	&-title {
		font-size: 15px;
		font-weight: 500;
		line-height: 1.5;
	}

	&-list {
		font-weight: 500;
		list-style: none;
		padding: 0;
	}

	&-item {
		color: $color-primary;
		font-size: 13px;
		line-height: 1.45;
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&-link {
		text-decoration: none;
		transition: color $motion-fast;

		&:hover {
			color: $color-action;
		}
	}

	&-text {
		font-size: 13px;
		line-height: 18px;
		margin-top: 16px;
	}
}
