.b-refinement_option {
	&-label {
		align-items: center;
		color: $color-primary;
		flex: 1 1 0;
		font-size: 13px;
	}

	&-count {
		color: $color-text-dimmed;
		line-height: 1;
		margin-left: 4px;
	}
}
