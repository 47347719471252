.b-category_link {
	display: flex;
	gap: 16px;
	margin-top: 20px;

	@include media(xl) {
		margin-top: 23px;
		max-width: $plp-content-width-max;
	}

	@include media(sm) {
		margin-top: 11px;
	}

	&-item {
		line-height: 15px;
		white-space: nowrap;
	}

	&-carousel {
		.b-carousel-ctrl {
			margin-top: 0;
		}

		.b-carousel-ctrl.m-prev {
			left: -15px;
		}

		.b-carousel-ctrl.m-next {
			right: -15px;
		}
	}
}
