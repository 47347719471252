.g-switcher {
	position: relative;

	&-label {
		background-color: $color-bg;
		border-radius: 22px;
		cursor: pointer;
		display: block;
		font-size: 0;
		height: 22px;
		position: relative;
		width: 40px;

		&::before {
			background-color: $color-action-obj;
			border-radius: 50%;
			content: '';
			display: block;
			height: 18px;
			left: 2px;
			position: absolute;
			top: 2px;
			transition: left $motion-ease;
			width: 18px;
		}
	}

	&-input:checked + &-label::before {
		left: 20px;
		transition: left $motion-ease;
	}

	&-input {
		height: 100%;
		opacity: 0;
		position: absolute;
		width: 100%;
	}
}
