/*md

# Refinement checkbox

TBD

*/

.b-refinement_checkbox {
	@include g-checkbox;

	cursor: pointer;

	&:hover {
		text-decoration: none;
	}

	&[aria-disabled='true'] {
		opacity: 0.6;
		pointer-events: none;
	}

	&-icon {
		@include g-checkbox(_icon);

		flex: 0 0 auto;

		.b-refinement_checkbox:active & {
			@include g-checkbox(_icon, m-active);
		}

		.b-refinement_checkbox {
			@include hover-supported {
				&:hover & {
					@include g-checkbox(_icon, m-hover);
				}
			}
		}

		.b-refinement_checkbox[aria-checked='true'] & {
			@include g-checkbox(_icon, m-checked);
		}

		.b-refinement_checkbox[aria-disabled='true'] & {
			@include g-checkbox(_icon, m-disabled);
		}
	}

	&.m-swatch &-icon_bg {
		color: $color-bg;
		fill: none;
	}

	/* stylelint-disable */
	&.-loading {
		.b-refinement_checkbox-icon {
			animation: rotate 0.75s linear infinite;
			background: transparent !important;
			border-color: $color-input-border;
			border-radius: 100%;
			border-style: inset;

			&_bg {
				fill: none !important;
			}
		}
	} /* stylelint-enable */

	&.m-swatch[aria-checked='true'] &-icon_bg {
		fill: $color-action-obj;
	}
}
